import create from 'zustand';

export enum LoginDrawerMode {
    LOGIN = 'login',
    SIGN_UP = 'signup',
    FORGOT_PASSWORD = 'forgotpassword',
    FORGOT_PASSWORD_SUBMITTED = 'forgotpasswordsubmitted',
    FORGOT_PASSWORD_SET_NEW = 'forgotpasswordsetnew',
    FORGOT_PASSWORD_ERROR = 'forgotpassworderror',
    CHANGE_PASSWORD = 'changepassword',
    CHANGE_PASSWORD_FAIL = 'changepasswordfail',
    CHANGE_PASSWORD_DONE = 'changepassworddone',
    CREATE_LOGIN = 'createlogin',
}

type UseLoginDrawer = {
    // The current open state
    isLoginDrawerOpen: boolean;
    loginDrawerMode: LoginDrawerMode;

    // Specify the open state
    setIsLoginDrawerOpen: (isOpen: boolean) => void;
    setLoginDrawerMode: (mode: LoginDrawerMode) => void;
};

/**
 * Global and shared store for the login drawer.
 * Avoid using directly - use the `useLoginDrawer` hook instead
 */
export const useLoginDrawerState = create<UseLoginDrawer>((set) => ({
    isLoginDrawerOpen: false,
    loginDrawerMode: LoginDrawerMode.LOGIN,
    setIsLoginDrawerOpen: (isLoginDrawerOpen) => set({ isLoginDrawerOpen }),
    setLoginDrawerMode: (loginDrawerMode) => set({ loginDrawerMode }),
}));
